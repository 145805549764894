<script setup lang="ts">
const { tenant } = useTenant()
const { backgroundColorClass } = useTheme()
</script>

<template>
  
  <div
    :class="[backgroundColorClass, tenant]"
    class="absolute flex min-h-screen w-full flex-col overflow-x-hidden"
  >
    <PublicHeader />
    <PublicCmsSidebarMenu />

    <div class="grow">
      <slot />
    </div>

    <FooterDefault />
    <TairoPanels />
  </div>
</template>
